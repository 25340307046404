import { useState } from "react";
import "../styles/Login.css";
import logo from "../assets/Aiq_Logo.svg";
import bro from "../assets/images/bro.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const HardCodedEmail_pass = {
		"anindita": "sjyud@vdf%rt",
		"tonyClark":"mjrdr$votg^r",
	}

	async function attemptLogin(event) {
		event.preventDefault();
		console.log("attemt login")
		// let {data} = await axios.post("https://demobe.nlugpt.io/copilot/login/", {
		// 	username, password 
		// });
		
		// if (data.response) {
		// 	if (
		// 		data.response ===
		// 		"Invalid Credential"
		// 	)
		// 		setError("Incorrect username or password");
		// 	else setError(data);
		// } else if (data.access) {
			if(username=="aiq-admin"&&password == "Admin@123"){
				localStorage.setItem("token", "dataaccess");
				navigate("/assitive_ai")
			}else{ 
				setError("Invalid username or password");
			}
			
		// 	// window.location = `https://test.nlugpt.io/?t=${response.token}`;
		// } else {
		// 	setError("Invalid response from server");
		// }
	}

	function handleNewUsername(event) {
		setUsername(event.target.value);
	}

	function handleNewPassword(event) {
		setPassword(event.target.value);
	}

	const handleLoginSubmitHardCoded = (e)=>{
		e.preventDefault()
		let keys = Object.keys(HardCodedEmail_pass)
		if(keys.includes(username) && HardCodedEmail_pass[username] === password){
			sessionStorage.setItem("lo","ok");
			navigate("/")
		}
		else{
			setError("Invalid Credintials");
		}
		
	}


	return (
		<div className="loginContainer">
			<div id="background"></div>
			<div id="login-container">
				<div id="login">
					<img src={logo} style={{width:167}} alt="Wolverine" />
					<div className="txt">
						<h1>Welcome Back!</h1>
						{error !== "" ? (
							<p className="error">{error}</p>
						) : (
							<p>Login to Continue</p>
						)}
					</div>
					<form>
						<input
							type="username"
							placeholder="Username"
							value={username}
							onChange={handleNewUsername}
						/>
						<input
							type="password"
							placeholder="Password"
							value={password}
							onChange={handleNewPassword}
						/>
						<input
							type="submit"
							className="white-on-blue"
							value="Log In"
							style={{cursor:"pointer"}}
							// onClick={attemptLogin}
							onClick={(e)=>attemptLogin(e)}
						/>
					</form>
					<p>
						{/* <a href="/reset-password">Forgot your password?</a> */}
					</p>
					<p>
						{/* Don't have an account? <a href="/signup">Sign up</a> */}
					</p>
				</div>
				<div id="security">
					<img src={bro} alt="" />
					<p>
						A strong password is the key to keeping your data
						secure.
					</p>
				</div>
			</div>
		</div>
	);
}
